.header {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-top: 12px;
    width: 100%;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  backdrop-filter: 'blur(10px)';
}

.profile-button {
    border: none;
    background: none;
}

.profile-button img {
    width: 35px;
}

.search-input {
    flex-grow: 1;
    /* margin: 0 5%; //Добавьте отступы слева и справа */
    width: 95%;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 18px;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
}

.search-input::placeholder {
    /* background: -webkit-linear-gradient(60deg, #fff6b7, #f6416c); */
    background-color: var(--tg-theme-hint-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.form-group {
    position: relative;
    flex-grow: 1;
    margin: 0;
    padding: 0; 
}

.form-group::after {
    position: absolute;
    margin-top: 3px;
    content: '';
    width: 95%;
    height: 2px;
    left: 0;
    bottom: 0;
    background: linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    animation: Gradient 5s ease infinite;
}


  .balance .gradient-button {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    border: none;
    color: white;
    padding: 2px 5px;
    text-align: center;
    text-decoration: none;
    display: flex; /* Изменено с inline-block на flex */
    font-size: 16px;
    cursor: pointer;
    border-radius: 20px;
    animation: Gradient 5s ease infinite;
    align-items: center; /* Теперь это будет работать */
    justify-content: center; /* Добавлено для выравнивания по горизонтали */
    text-decoration: none;
  }
  
  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }