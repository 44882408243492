.product {
display: flex;
flex-direction: column;
padding: 10px;
width: 100%;
}


.img {
width: 100%;
background: var(--tg-theme-bg-color);
margin: 0 auto;
border-radius: 50%;
}

.description {
font-size: 0.8em;
}

.add-btn {
width: 100%;
}

.product-title {
text-align: center; 
line-height: 1.2;
margin-top: 5px;
font-size: 20px;
background: var(--tg-theme-bg-color);
}

.product-price{
  font-size: 20px;
  background: var(--tg-theme-bg-color);
}

.product-img {
border-radius: 15px;
width: 100%;
background: var(--tg-theme-secondary-bg-color);
}

.product-stars{
  background: var(--tg-theme-bg-color);
  font-size: 16px;
}

.review-count{
  font-size: 16px;
  align-items: center;
  color: rgba(255,255,255,0.8);
  background-color: #FEA620;
}

.filled-stars {
color: #fca95d;
}

.star-icon{
  color: #fca95d;
}

.empty-stars {
color: rgb(176, 176, 176);

}

.large-font {
font-size: 18px; 
}

.bold-font {
font-weight: bold;
}

.avatar-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ccc;
    color: #fff;
    font-size: 20px;
    background: var(--tg-theme-secondary-bg-color);
  }

  .ml-3 {
    margin-left: 1rem; /* или другой размер, который вам нужен */
  }

  .review-user-name{
    font-size: 16px;
    font-weight: bold;
    background: var(--tg-theme-secondary-bg-color);
  }

  .review-rating{
    font-size: 16px;
    background: var(--tg-theme-secondary-bg-color);
  }

  .review-date{
    font-size: 14px;
    background: var(--tg-theme-secondary-bg-color);
  }

  .card-text{
    font-size: 14px;
    background: var(--tg-theme-secondary-bg-color);
  }

  .header-review{
    font-size: 16px;
  }

  .card{
    border: none;
    background: var(--tg-theme-secondary-bg-color);
  }

  .has-more{
    background-color: transparent;
    color: var(--tg-theme-text-color);
    border: none;
    display: block;
    margin: 0 auto;
    margin-top: 3 rem;
  }

  .rating-style{
    background: #FEA620;
    border-radius: 15px;
    padding: 3px 8px;
  }

  .unfilled-stars-page {
    color: white;
    background: #FEA620;
    }

  .warning-block{
    background-color: #FCF4E4;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 36px;
    margin-top: 10px;
  }

  .warning-text{
    color: black;
    font-size: 11px;
    background-color: transparent;
  }

  .exclamation{
    background: transparent;
    padding: 0 5px;
  }