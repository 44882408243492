.profile-container {
    display: flex;
    align-items: center;
  }
  
  .profile-info {
    margin-left: 20px;
  }
  
  .profile-name{
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    color: white;
  }

  .profile-username {
    font-size: 14px;
    font-style: italic;
    margin: 0;
  }

  .w-100 {
    width: 100%;
  }
  
  .w-50 {
    width: 50%;
  }
  
  .transition-btn {
    transition: background-color 0.3s ease;
  }

  .profile-image, .avatar-placeholder {
  width: 75px; /* Задайте нужный размер */
  height: 75px; /* Задайте нужный размер */
  border-radius: 50%;
  object-fit: cover;
}

.profile{
  padding: 10px;
  border-radius: 10px;
}

.profile-avatar-placeholder{
  width: 75px;
  height: 75px;
  font-size: 32px;
}

.promo-response{
  color: var(--tg-theme-text-color)
}

.input-group__promo {
  color: var(--tg-theme-text-color);
  font-size: 1.25rem;
  line-height: 1;
  border-style: none;
  outline: none;
  height: 32px;
  width: 100%;
  padding: 8px 10px;
  background-image: var(--tg-theme-bg-color);
  /* border: 2px solid transparent; */
  /* background-image: linear-gradient(var(--tg-theme-bg-color), var(--tg-theme-bg-color)), linear-gradient(120deg, #f09 0%, #0ff 50%, #9f0 100%); */
  background-image: linear-gradient(var(--tg-theme-bg-color), var(--tg-theme-bg-color));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-size: 200% 100%;
  transition: background-position 0.8s ease-out;
  background-color: var(--tg-theme-bg-color);
  border-radius: 5px;
}

.input-group__input:hover {
  background-position: 100% 0;
}

.ref-container {
  display: flex;
  font-size: 10px;
}

.promo-container {
  display: flex;
  font-size: 10px;
}

.ref-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-top: 12px;
  width: 100%;
}

.promo-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-top: 12px;
  width: 100%;
}

ref-button {
  background: none;
  border: none;
}

.ref-link{
  font-size: 14px;
}

.ref {
  margin: 10px 0;
  background-color: var(--tg-theme-secondary-bg-color);
  border-radius: 12px;
  padding: 5px;
  /* border: 2px dashed var(--tg-theme-hint-color); */
  border-color: linear-gradient(45deg, #9A9A9A, #454545);;
  border-radius: 12px;
}

.promo {
  margin: 10px 0;
  background-color: var(--tg-theme-secondary-bg-color);
  border-radius: 12px;
  padding: 5px;
  /* border: 2px dashed var(--tg-theme-hint-color); */
  border-color: linear-gradient(45deg, #9A9A9A, #454545);;
  border-radius: 12px;
}


.ref-icons{
  padding: 8px;
}

button {
  z-index: 1;
}

.profile-balance .profile-gradient-button {
  background-image: linear-gradient(91.1deg, rgb(57, 31, 105) -2.3%, rgb(115, 43, 155) 44.4%, rgb(231, 75, 184) 103.4%);
  color: whitesmoke;
  background-size: 200% 200%;
  border: none;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  animation: Gradient 5s ease infinite;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 96%;
  height: 30px;
}


@keyframes gradient_move {
  0%{background-position: 0% 92%}
  50%{background-position: 100% 9%}
  100%{background-position: 0% 92%}
}

.gradient-text {
  color: var(--tg-theme-text-color);
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* .profile-image {
  position: relative;
  z-index: 1;
}

.profile-image::after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  background: linear-gradient(122.3deg, rgb(111, 71, 133) 14.6%, rgb(232, 129, 166) 29.6%, rgb(237, 237, 183) 42.1%, rgb(244, 166, 215) 56.7%, rgb(154, 219, 232) 68.7%, rgb(238, 226, 159) 84.8%);
  background-size: 200% auto;
  animation: gradient 5s linear infinite;
  z-index: -1;
  border-radius: 50%;
} */

/* .jam {
  flex-shrink: 0;
} */


