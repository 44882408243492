.category {
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px 0px;
    width: 40%;
}

.img {
    width: 70%;
    background: var(--tg-theme-bg-color);
    height: 100px;
    margin: 0 auto;
    background: var(--tg-theme-button-color);
    border-radius: 50%;
}

.description {
    font-size: 0.8em;
}

.add-btn {
    width: 100%;
}

.category-title {
    margin-top: 5px;
    text-align: center; /* Выравнивает текст по центру */
    line-height: 1.2;
    font-size: 14px;
}

.category-img {
    width: 75px; /* Устанавливает ширину изображения */
    background: var(--tg-theme-bg-color);
    margin: 0 auto;
    background: var(--tg-theme-button-color);
    border-radius: 25%; /* Делает изображение круглым */
}