.add-product-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1em;
  }
  
  .form-input,
  .form-select,
  .form-checkbox {
    margin-bottom: 1em;
    width: 100%;
  }

  .admin-title{
    color: var(--tg-theme-text-color);
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .admin-input{
      border: 2px solid var(--tg-theme-text-color);
      border-radius: 4px;
      height: 1.8 rem;
      font-size: 1.2rem;
      padding: 2%;
  }

  .admin-container{
    display: flex;
    flex-direction: column;
    padding: 5px;
  }

  .admin-button{
    border: 2px solid var(--tg-theme-text-color);
    border-radius: 4px;
    height: 1.8 rem;
    font-size: 1.2rem;
    background-color: blueviolet;
  }