@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body {
  font-family: 'Inter', sans-serif;
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
}

.App {
  overflow-y: hidden; /* Убирает вертикальный скролл */
  background: var(--tg-theme-bg-color);
  overflow: "hidden";
}

::-webkit-scrollbar {
  width: 0px; /* Ширина полосы прокрутки */
  background: var(--tg-theme-bg-color);
}

/* ::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #ffd900, #f00);
  background: var(--tg-theme-subtitle-text-color);
  border-radius: 5px; 
} */


/* ::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #ffd900, #f00);
  background: var(--tg-theme-subtitle-text-color);
}  */
