.image-container {
  display: flex;
  flex-direction: column;
}

.image-block {
  width: 100%; 
  margin: 10px 0;
  position: relative;
  overflow: hidden;
}

.image-block::before {
  content: "";
  display: block;
  padding-top: 50%;
}

.image-block img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
