.replenish-page {
    position: relative;
    min-height: 100vh;
    padding: 10px 10px 60px 10px; /* Height of the footer */
  }
  
  .fixed-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  .vertical .btn {
    display: block;
    width: 100%;
  }

  .input-group__input {
    color: var(--tg-theme-text-color);
    font-size: 1.25rem;
    line-height: 1;
    border-style: none;
    outline: none;
    height: 2em;
    width: 100%;
    padding: 0.5em 0.5em;
    border: 2px solid transparent;
    /* background-image: linear-gradient(var(--tg-theme-bg-color), var(--tg-theme-bg-color)), linear-gradient(120deg, #f09 0%, #0ff 50%, #9f0 100%); */
    /* background-image: linear-gradient(var(--tg-theme-bg-color), var(--tg-theme-bg-color)), linear-gradient(120deg, #C7210A 0%, #FEA534 50%, #FF8A34 100%); */
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 200% 100%;
    transition: background-position 0.8s ease-out;
    background-color: var(--tg-theme-bg-color);
    border-radius: 10px;
  }
  