.list {
    display: flex;
    padding: 0 5%;
    flex-wrap: wrap;
    justify-content: center;
}

.item {
    padding: 5px;
}

/* .d-flex {
  padding-left: 5%;
  padding-right: 5%;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
} */

  
  .d-flex::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  
  .d-flex::-webkit-scrollbar-track {
    background: var(--tg-theme-bg-color);
  }
  
  /* .d-flex::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #ffd900, #f00);

  } */

  .game-title {
    text-align: left; /* Выравнивает текст по центру */
    /* color: var(--tg-theme-section-header-text-color); */
    color: var(--tg-theme-text-color);
    font-weight: 400;
    font-size: 18px;
    align-items: center;
    padding-left: 5px;
    /* Добавьте другие стили по вашему усмотрению */
}

.container {
  margin-left: 10px;
  margin-right: 10px;
}
