.product {
flex-direction: column;
text-decoration: none;
/* border: 0.5px solid var(--tg-theme-subtitle-text-color); */
padding: 10px; /* Отступ внутри контейнера */
border-radius: 15px; /* Скругление углов */

    margin-bottom: 10px; /* Отступ снизу */
    /* box-shadow: 4px 4px 8px 0px var(--tg-theme-subtitle-text-color); */
    /* box-shadow: 4px 4px 8px 0px #FEA620; */
    background-color: var(--tg-theme-secondary-bg-color);
    
}
.price{
    background: var(--tg-theme-secondary-bg-color);
}
.img {
width: 100%;
background: var(--tg-theme-bg-color);
margin: 0 auto;
border-radius: 50%;
}

.description {
font-size: 0.8em;
}

.add-btn {
width: 100%;
}

.title {
text-align: center; 
line-height: 1.2;
margin-top: 5px;
background: var(--tg-theme-secondary-bg-color);
}

.product-img {
border-radius: 15px;
}

.unfilled-stars {
color: white;
background: #FEA620;
}

.empty-stars {
color: rgb(176, 176, 176);
background: var(--tg-theme-secondary-bg-color);

}

.large-font {
font-size: 18px; 
}

.bold-font {
font-weight: bold;
}

.game-name{
    color: var(--tg-theme-subtitle-text-color);
    background: var(--tg-theme-secondary-bg-color);
}

.rating{
    color: white;
    background: #FEA620;
}

.stars{
    background: #FEA620;
    border-radius: 10px;
    padding: 0 8px;
}

.product.focused,
.product.focused * {
  background-color: var(--tg-theme-hint-color);
}