.radio-inputs1 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: #EEE;
    box-sizing: border-box;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    padding: 0.25rem;
    width: 300px;
    font-size: 14px;
  }
  
  .radio-inputs1 .radio1 {
    flex: 1 1 auto;
    text-align: center;
  }
  
  .radio-inputs1 .radio1 input {
    display: none;
  }
  
  .radio-inputs1 .radio1 .name1 {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: none;
    padding: .5rem 0;
    color: rgba(51, 65, 85, 1);
    transition: all .15s ease-in-out;
  }
  
  .radio-inputs1 .radio1 input:checked + .name1 {
    background-color: #fff;
    font-weight: 600;
  }

  .transition-btn {
    position: relative;
    overflow: hidden;
  }
  
  .transition-btn::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: purple;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }
  
  .transition-btn.active::after {
    transform: scaleX(1);
  }

  .transition-btn.left::after {
    transform-origin: right;
    background: linear-gradient(90deg,#7c04fd, #fb26b9);
  }
  
  .transition-btn.right::after {
    transform-origin: left;
    background: linear-gradient(90deg,#fb26b9,#7c04fd);
    /* background: linear-gradient(-45deg, #23a6d5, #23d5ab); */
  }

  .text-gradient-left {
    background: linear-gradient(90deg,#7c04fd, #fb26b9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
  }

  .text-gradient-right {
    background: linear-gradient(90deg,#fb26b9,#7c04fd);
    /* background: linear-gradient(-45deg, #23a6d5, #23d5ab); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .text-normal {
    color: var(--tg-theme-text-color);
  }

  .text-gradient-red {
    color: '#FA2A64';
  }

  .text-gradient-green {
    color: '#2FB382';
  }