.list {
    display: flex;
    padding: 2px 10px;
    flex-wrap: wrap;
    justify-content: center;
}

.item {
    /* padding: 10px; */
    width: 100%;
    text-decoration: none;
    padding-top: 10px;
}
  
  .d-flex::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  
  .d-flex::-webkit-scrollbar-track {
    background: var(--tg-theme-bg-color);
  }
/*   
  .d-flex::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #ffd900, #f00);

  } */

  .game-title {
    text-align: left;
    font-size: 18px;
    margin: 0;
}

.custom-font-size {
  font-size: 18px; 
}

.header-container{
  margin: 0px 10px;
}

.no-underline {
  text-decoration: none;
}

.dropdown-toggle-custom {
  background-color: var(--tg-theme-secondary-bg-color) !important;
}

.dropdown-item:hover {
  background-color: var(--tg-theme-bg-color) !important;
}

.dropdown-item{
  background-color: var(--tg-theme-text-color);
}

.pagination {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0 0 0 ;
  position: relative;
  width: 100%;
  background-color: transparent; /* Добавьте цвет фона, чтобы текст под пагинацией не был виден */

}

.page-number {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
}


.page-number.active {
  color: #800080; /* Фиолетовый цвет для активной страницы */
}

.prev-page, .next-page {
  padding: 5px 10px;
  border: none;
  background-color: transparent; /* Светло-фиолетовый цвет */
  cursor: pointer;
  font-size: 16px;
}

